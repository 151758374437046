/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Arrangements from 'components/Arrangements'
import Halls from 'components/Halls'
import ArrangementPrices from 'components/ArrangementPrices'
import MoreInformation from 'components/MoreInformation'

// Elements
import HeroBig from 'components/elements/HeroBig'
import ContentDefault from 'components/elements/ContentDefault'
import TitleSub from 'components/elements/TitleSub'
import BrandTitle from 'components/elements/BrandTitle'
import ButtonDefault from 'components/elements/ButtonDefault'
import ContainerSmall from 'components/elements/ContainerSmall'
import MoodImage from 'components/elements/MoodImage'

const IntroImage = styled(BackgroundImage)`
  background-size: contain;
  background-position: top;

  @media (min-width: 992px) {
    width: 550px;
    height: 400px;
  }

  @media (max-width: 991px) {
    width: 450px;
    height: 250px;
  }

  @media (max-width: 575px) {
    height: 200px;
  }
`

const VideoWrapper = styled.section`
  background-color: ${props => props.theme.color.secondary};
`

const Video = styled.div`
  margin: 0 auto;
  display: block;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 3px rgba(0,0,0,0.5);
  position: relative;

  & > video {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 992px) {
    width: 500px;
    height: 281px;
  }

  @media (max-width: 991px) {
    width: 450px;
    height: 254px;
  }

  @media (max-width: 767px) {
    width: 350px;
    height: 196px;
  }

  @media (max-width: 575px) {
    width: 250px;
    height: 141px;
  }
`

export const pageQuery = graphql`
  {
    page: wordpressPage(wordpress_id: { eq: 2 }) {
      title
      path
      acf {
        backgroundImage: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              meta: fluid(maxWidth: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
        bannerContent: banner_content

        introTitle: intro_title
        introDescription: intro_description
        introButtonText: intro_button_text
        introButtonLocation: intro_button_location
        introImage: intro_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 550) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }

        arrangementen

        videoTitle: video_title
        videoDescription: video_description
        videoFile: video_url

        contactRelation: contact_relation

        callToActionRelation: cta_relation

        moodImage: view_mood_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const HomeTemplate = ({
  data: {
    page: { path, yoastMeta, acf },
  },
}) => {
  return (
    <Layout>
      <SEO
        yoast={{ meta: yoastMeta }}
        pathname={path}
        image={acf.backgroundImage.localFile.childImageSharp.meta.src}
        article
      />
      <HeroBig
        content={acf.bannerContent}
        image={acf.backgroundImage}
      />
      <Arrangements />
      <div className="container">
        <section className="row">
          <div className="col-lg-6 d-flex d-lg-none justify-content-center">
            <IntroImage fluid={acf.introImage.localFile.childImageSharp.fluid} />
          </div>
          <div className="col-lg-6 pt-3 pt-lg-5">
            <TitleSub>
              {acf.introTitle}
            </TitleSub>
            <BrandTitle />
            <ContentDefault className="mt-5" content={acf.introDescription} />
            <ButtonDefault to={acf.introButtonLocation} className="mt-4">
              {acf.introButtonText}
            </ButtonDefault>
          </div>
          <div className="col-lg-6 d-none d-lg-flex">
            <IntroImage fluid={acf.introImage.localFile.childImageSharp.fluid} />
          </div>
        </section>
      </div>

      <div className="container">
        <Halls className="mt-5 pt-5" />
      </div>

      <div className="pt-5">
        <MoreInformation id={acf.contactRelation} className="mt-5" />
      </div>

      <ContainerSmall className="mt-5 py-5">
        <ArrangementPrices className="mb-lg-5 pb-5" ids={acf.arrangementen} />
      </ContainerSmall>

      <VideoWrapper className="mt-5 py-lg-3">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-5">
              <TitleSub>
                {acf.videoTitle}
              </TitleSub>
              <BrandTitle />
              <ContentDefault className="mt-5" content={acf.videoDescription} />
            </div>
            <div className="col-lg-1" />
            <div className="col-lg-6 mt-4 mt-lg-0 pb-5 pb-lg-0">
              <Video>
                <video controls>
                  <source src={acf.videoFile} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Video>
            </div>
          </div>
        </div>
      </VideoWrapper>

      {acf.moodImage && (
        <MoodImage image={acf.moodImage} />
      )}
    </Layout>
  )
}

export default HomeTemplate