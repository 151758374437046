/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled, { css } from 'styled-components'
import parse from 'html-react-parser'

// Components
import CustomLink from 'components/shared/CustomLink'

const arrangementWidth = 225

const StyledArrangements = styled.div`
  background-color: ${props => props.theme.color.dark};
`

const Title = styled.h3`
  font-family: ${props => props.theme.font.family.secondary};
  font-weight: ${props => props.theme.font.weight.m};
  font-size: ${props => props.theme.font.size.l};
  color: ${props => props.theme.color.text.secondary};
  text-transform: uppercase;
  max-width: 125px;
  transition: all .1s;
`

const Arrangement = styled(CustomLink)`
  background-color: ${props => props.theme.color.light};
  position: relative;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  ${({ lower }) => lower ? css`
  @media (min-width: 992px) {
    width: ${arrangementWidth}px;
    top: 0;
    margin-top: 0;
  }` : css`
  @media (min-width: 992px) {
    width: ${arrangementWidth}px;
    top: ${props => (props.inside ? 0 : '-75px')};
    margin-top: ${props => (props.inside ? '75px' : 0)};
  }`}

  &:hover {
    & ${Title} {
      font-size: ${props => parseFloat(props.theme.font.size.l.replace('px')) + 2}px;
      max-width: 140px;
      text-decoration: underline;
    }
  }

  /* @media (min-width: 992px) {
    width: ${arrangementWidth}px;
    top: ${props => (props.inside ? 0 : '-75px')};
    margin-top: ${props => (props.inside ? '75px' : 0)};
  } */

  @media (max-width: 991px) {
    width: 175px;
  }
`

const Hexagon = styled.div`
  background-color: ${props => props.theme.color.light};
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 1;
  width: ${arrangementWidth}px;
  height: ${arrangementWidth / 1.7}px;
  border-radius: 7px;
  transition: opacity 0.5s;

  &:before,
  &:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: '';
  }

  &:before {
    transform: rotate(60deg);
  }

  &:after {
    transform: rotate(-60deg);
  }

  @media (max-width: 991px) {
    display: none;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 2;
  text-align: center;
  bottom: 20px;
`

const Icon = styled(BackgroundImage)`
  display: block;
  margin: 0 auto;
  height: 35px;
  width: 35px;
  background-size: contain;
`

const Arrangements = ({ className, inside }) => {
  const {
    allWordpressPage: { edges: items },
  } = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: { parent_element: { wordpress_id: { eq: 7 } } }
        sort: { order: ASC, fields: menu_order }
      ) {
        edges {
          node {
            wpId: wordpress_id
            title
            path
            acf {
              viewIcon: view_icon {
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 35) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledArrangements className={className}>
      <div className="container pb-5 px-xl-5">
        <div className="row pb-0 pb-lg-3 px-xl-5">
          {items.map(({ node: { wpId, title, path, acf: { viewIcon } } }, index) => (
            <div
              className="col-md d-flex justify-content-center mt-5 px-xl-5"
              key={wpId}
            >
              <Arrangement lower={index >= 3} to={path} inside={inside}>
                <Hexagon />
                <Content className="pt-5 pt-lg-0">
                  <Icon
                    fluid={viewIcon.localFile.childImageSharp.fluid}
                    alt={title}
                  />
                  <Title className="mt-4">{parse(title)}</Title>
                </Content>
              </Arrangement>
            </div>
          ))}
        </div>
      </div>
    </StyledArrangements>
  )
}

export default Arrangements
