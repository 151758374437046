/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'

const StyledContainerSmall = styled.div`
  @media (min-width: 1200px) {
    max-width: 900px;
  }
`

const ContainerSmall = ({ children, className }) => (
  <StyledContainerSmall className={`container ${className}`}>
    {children}
  </StyledContainerSmall>
)

export default ContainerSmall