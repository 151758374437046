/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import parse, { domToReact } from 'html-react-parser'
import { StyledTitleDefaultCSS } from 'components/elements/TitleDefault'
import ButtonDefault from 'components/elements/ButtonDefault'
import { HeroCSS, HeroImageCSS, HeroGradientCSS } from './HeroShared'

const StyledHeroBig = styled.div`
  ${HeroCSS};
  
  @media screen and (min-width: 992px) {
    height: 70vh;
    min-height: 650px;
    max-height: 950px;
  }

  @media screen and (max-width: 991px) {
    height: 550px;
  }
`

const Image = styled.div`
  ${HeroImageCSS};
`

const Gradient = styled.div`
  ${HeroGradientCSS};
`

const Content = styled.div`
  position: relative;
  z-index: 2;

  & > h1 {
    ${StyledTitleDefaultCSS};
  }

  @media screen and (max-width: 991px) {
    width: 100%;
    text-align: center;

    & > h1 {
      font-size: ${props => props.theme.font.size.xxl};
    } 
  }
`

const HeroBig = ({ image, content, className }) => (
  <StyledHeroBig className={className}>
    <Image>
      <Gradient />
      <BackgroundImage fluid={image.localFile.childImageSharp.fluid} />
    </Image>
    <div className="container d-flex align-items-center h-100 pt-5">
      <Content className="pt-5">
        {parse(content, {
          replace: domNode => {
            if(domNode.name === 'a') {
              const attributes = domNode.attribs

              if(attributes.style) {
                delete attributes.style
              }
        
              return (
                <ButtonDefault to={attributes.href}>
                  {domToReact(domNode.children, {})}
                </ButtonDefault>
              )
            }

            return true
          },
        })}
      </Content>
    </div>
  </StyledHeroBig>
)

export default HeroBig